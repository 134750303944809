/*
 * Asset Builder has a quirk that allows custom scripts 
 * to load before Bower scripts during concatenation. 
 * To get around this, use one of the following methods.
 *
 * IIFE
 * (function(){ code goes here }());
 *
 * Self-executing anonymous function
 * var foo = function() { code goes here };
 *
 * document.ready (jQuery)
 * $(document).ready(function() { code goes here });
 * $(function() { code goes here });
 *
 * window.onload (Javascript)
 * window.onload = function() { code goes here };
 *
 *
 *
 */

/*eslint-disable */
$(document).ready(function(){
  $('input.timepicker').timepicker({
    'timeFormat': 'H:i',
    'stopScrollPropagation': true,
    'scrollDefault': '08:00',
    'step': 5
  });


  // var boxes = $(".switchradio input:checkbox").click(function(){
  //     boxes.not(this).attr('checked', false);
  // });


  /**
   * slider intensité
   */
  if( $('.slider-intensite').length ) {
    // TEMPS ON
    var paramSlider = {
          behaviour: 'tap',
          // step: 1,
          connect: [true, false],
          animate: true,
          animationDuration: 300,
          start: 50,
          tooltips:  wNumb({ decimals: 0, suffix: 's' }),
          range: {
            'min': [ 1 ],
            'max': [ 120 ]
          },
          pips: {
              mode: 'values',
              values: [1, 30, 60, 90, 120],
              density: 3,
              format: wNumb({
                    decimals: 0,
                    suffix: 's'
                  })
            }
    };

    /*
    ON VALUES
     */
    var onValue = $('#slider-intensite-on').data('init');
    var unit    = $('#slider-intensite-on').data('unit');
    var sliderIntensityOn = document.getElementById('slider-intensite-on');

    paramSlider.start = onValue;
    paramSlider.tooltips  = wNumb({ decimals: 0, suffix: unit });
    paramSlider.pips.format = wNumb({ decimals: 0, suffix: unit });
    noUiSlider.create(sliderIntensityOn, paramSlider);
    
    /*
    OFF VALUES
     */
    var sliderIntensityOff = document.getElementById('slider-intensite-off');
    var offValue = $('#slider-intensite-off').data('init');
    var unit     = $('#slider-intensite-off').data('unit');

    paramSlider.start = offValue;
    paramSlider.tooltips  = wNumb({ decimals: 0, suffix: unit });
    paramSlider.pips.format = wNumb({ decimals: 0, suffix: unit });
    noUiSlider.create(sliderIntensityOff,paramSlider);

    $(document).on('change', '#intensiteunitOn', function(event) {
      event.preventDefault();
      var unit =$(this).val();
      sliderIntensityOn.noUiSlider.destroy()
      paramSlider.tooltips    = wNumb({ decimals: 0, suffix: unit });
      paramSlider.pips.format = wNumb({ decimals: 0, suffix: unit });
      
      noUiSlider.create(sliderIntensityOn, paramSlider);
    });

    $(document).on('change', '#intensiteunitOff', function(event) {
      event.preventDefault();
      var unit =$(this).val();
      sliderIntensityOff.noUiSlider.destroy()
      paramSlider.tooltips    = wNumb({ decimals: 0, suffix: unit });
      paramSlider.pips.format = wNumb({ decimals: 0, suffix: unit });

      noUiSlider.create(sliderIntensityOff, paramSlider);
    });

  } // Fin si $.slider-intensite .length

  /*
  *Impersonate
  */
  $(document).on('click', '.btn-im', function(event) {
    event.preventDefault();
    console.log('clock');
    window.location.href = $(this).data('url') + $('#imname').val();
  });


  $(document).on('click', '.btn-save-intensity', function(event) {
    event.preventDefault();
    var $this = $(this);
    var idDiffuser = $(this).data('id');
    var textButton = $this.html();

    $this.prop("disabled", true).html($this.data('loading'));
    var ton  = sliderIntensityOn.noUiSlider.get();
    var toff = sliderIntensityOff.noUiSlider.get();

    $.ajax({
      type: 'POST',
      url: Routing.generate('diffuser_user_update_intensity', { id: idDiffuser }), // eslint-disable-line
      data: {
        'ton' :ton,
        'toff':toff,
        'tonunit':$('#intensiteunitOn').val(),
        'toffunit':$('#intensiteunitOff').val()
      },
      success: function (data) {
        notie.alert({
          type: 'success',
          text: 'Enregistrement effectué !',
          // stay: Boolean, // optional, default = false
          // time: Number, // optional, default = 3, minimum = 1,
          position: 'bottom'
        })
      }
    }).always(function() {
      $this.html(textButton).prop("disabled", false);;
    });
  });


});


$(function () {
  $('[data-toggle="tooltip"]').tooltip()
})

/*eslint-disable */

$(document).ready(function() {
      var idDiffuser = $('#calendar').data("id");

      var oldRowDate = '';
      var plage      = 0;

      var cal = $('#calendar').fullCalendar({
        editable: false,
        weekends: true,
        viewRender: function (element) {

          $('#override-week :input').val('');
          //On désactive les jours de non diffusion
          $("#override-week .plage-horaires").show();
          $("#override-week .no-diffusion").hide();
 
          oldRowDate = '';
          plage      = 0;

          var moment = $('#calendar').fullCalendar('getDate').startOf('week');
          $('.delete-custom-horaires').attr('data-weekstart', moment.format("YYYY-MM-DD"));
          
          var day = $('#calendar').fullCalendar('getDate').startOf('week');
          var weekDelta = moment.format("L") +' au '+ moment.add(7, 'days').format("L");
          $('#weekToChange').text(weekDelta);

          //On remplit le tableau avec de la semaine de Surcharge avec les bonnes dates.
          $.each($('.day-date'), function(index, val) {
            $(this).text( day.format('L') );
            $(this).closest('tr').attr('data-currentday', day.format('DD[-]MM[-]YYYY') );
            day.add(1, "days");
          });
        },
        eventRender: function(event, element) {
                if(event.type === 'week') {

                  var table = $('#override-week');
                  var ev = event.date;

                  if( table.find("[data-currentday='" + ev + "']").length ) {

                    var row = table.find("[data-currentday='" + ev + "']");
                    if(oldRowDate !== ev){
                      plage = 0;
                      oldRowDate = ev;
                    }
                    if( event.noDiffusion === true ) {
                      row.find('.switch-input').trigger('click');
                    } else {
                      var sh = event.start.format('HH:mm');
                      var eh = event.end.format('HH:mm');

                      row.find('input.pstart:eq('+plage+')').val(sh);
                      row.find('input.pend:eq('+plage+')').val(eh);
                    }

                    plage++;
                  }

                }
        },
        defaultView: 'agendaWeek', // Only show week view
        // slotDuration: '00:30:00',
        // snapDuration: '00:15:00',
        allDaySlot: false,
        height:950,
        firstHour: 5,
        header: {
           left: 'prev,next ',
           center: 'title',
           right: 'today'
           // right: 'month,agendaWeek,agendaDay'
        },
        buttonText: {
           today: 'Aujourd\'hui',
           month: 'Mois',
           week: 'Sem.',
           day: 'Jr'
        },
        events: {
                url: Routing.generate('diffuser_user_planning', { id: idDiffuser }),
                type: 'POST',
                error: function() {
                    console.log('there was an error while fetching events!');
                },
        },
      });

      $(document).on('click', '#next-week, #prev-week', function(event) {
        event.preventDefault();
        $("#override-week input[type='checkbox']").prop('checked', "");
        if( $(this).hasClass('prev') ) {
          $('#calendar').fullCalendar('prev');
        } else {
          $('#calendar').fullCalendar('next');
        }
        /* Act on the event */
      });

      $(document).on('click', '.erase-week', function(event) {
        event.preventDefault();
        $(this).parent().find('input[type="text"]').val('');
      });

     // Sauvegarde du planing générique
     $(document).on('click', '.btn-save-default-week', function(event) {
       event.preventDefault();
       var $this = $(this);
       var idDiffuser = $(this).data('id');
       var textButton = $this.html();

       $this.html($this.data('loading')).prop("disabled", true);

       // $('#default-week table tr')
       var week = ['0','1','2', '3', '4', '5', '6'];
       var planning = {'0':{},'1':{},'2':{}, '3':{}, '4':{}, '5':{}, '6':{}};
       var i = 0;
       var k = 0;
       var j = 0;
       // var currentKey = 0;
       $.each( $('#default-week .plage-horaires'), function(index, val) {
          if(i % 3 == 0 ) {
             currentKey = week[k];
             k++ ;
             j = 0 ;
            //On initialize le tableau pour pouvoir insérer nos valeurs.
            planning[currentKey]    = {};
          }

          var p1 = $(this).find('.pstart').val();
          var p2 = $(this).find('.pend').val();

          
          //On initialize le tableau pour pouvoir insérer nos valeurs.
          planning[currentKey][j] = {};
          //On insere les valeurs
          planning[currentKey][j].start = p1;
          planning[currentKey][j].end   = p2;
          i++;
          j++;
       });

       $.ajax({
         type: 'POST',
         url: Routing.generate('diffuser_user_update_default_planning', { id: idDiffuser }), // eslint-disable-line
         data: {
           'planning' : planning
         },
         success: function (data) {
           notie.alert({
             type: 'success',
             text: 'Enregistrement effectué !',
             position: 'bottom'
           });

            cal.fullCalendar( 'refetchEvents' );


         }
       }).always(function() {
          $this.html(textButton).prop("disabled", false);
       });
     });

     // Sauvegarde du planing de la semine courante@
     $(document).on('click', '.btn-save-c-week', function(event) {
       
       event.preventDefault();       
       // Raz des valeurs
       oldRowDate = '';
       plage      = 0;

       var $this = $(this);
       var idDiffuser = $(this).data('id');
       var textButton = $this.html();

       $this.html( $this.data('loading') ).prop("disabled", true);

       var week = ['0','1','2', '3', '4', '5', '6'];
       var p = {};
       var i = 0;
       var k = 0;
       var j = 0;
       
       //On parcours chaque ligne de tableau
       $.each( $('#override-week table tbody tr'), function(index, val) {

            var d = $(this).data('currentday');
            console.log(d);
            p[d] = {};
            // On regarde si la journée a été désactivée.
            var noDif = $(this).find('.switch-input');
            if(noDif.is(':checked') ) {
              p[d][index] = {};
              p[d][index].noDiffusion   = true;
            } 
            else {
              $.each($(this).find('.plage-horaires'), function(index, val) {
                var p1 = $(this).find('.pstart').val();
                var p2 = $(this).find('.pend').val();

                p[d][index] = {};
                p[d][index].start = p1;
                p[d][index].end   = p2;

              });
            }
       });

       $.ajax({
         type: 'POST',
         url: Routing.generate('diffuser_user_update_planning', { id: idDiffuser }), // eslint-disable-line
         data: {
           'planning' : p
         },
         success: function (data) {
          //Clear le calendrier formulaire
          $('#override-week :input').val('');
          $("#override-week input[type='checkbox']").prop('checked', "");
           cal.fullCalendar( 'refetchEvents' );
           notie.alert({
             type: data.type,
             text: data.text,
             position: 'bottom'
           });

         }
       }).always(function() {
         $this.html(textButton).prop("disabled", false);
       });


     });

    // Sauvegarde du planing de la semine courante@
    $(document).on('click', '.delete-custom-horaires', function(event) {
       
      event.preventDefault();
      var $this = $(this);
      var week = $this.attr('data-weekstart');

       notie.confirm({
             text: 'Toute suppression est définitive ! Supprimer tout de même ?',
             submitText: "Oui", // optional, default = 'Yes'
             cancelText: 'Annuler', // optional, default = 'Cancel'
             position: 'top', // optional, default = 'top', enum: ['top', 'bottom']
             submitCallback: function(){
               var idDiffuser = $this.data('id');
               var textButton = $this.html();

               $this.html( $this.data('loading') ).prop("disabled", true);

               $.ajax({
                 type: 'POST',
                 url: Routing.generate('diffuser_user_delete_custom_planning', { id: idDiffuser }), // eslint-disable-line
                 data: {
                  week : week
                 },
                 success: function (data) {
                   cal.fullCalendar( 'refetchEvents' );
                   $('#override-week input[type="text"]').val('');
                   notie.alert({
                     type: data.type,
                     text: data.text,
                     position: 'bottom'
                   });

                 }
               }).always(function() {
                 $this.html(textButton).prop("disabled", false);
               });
             }
       });


     });

     $(document).on('click', '.empty-times', function(event) {
       event.preventDefault();
       $(this).parent().find("input").val('');
     });

     $(document).on('click', '.week-lock', function(event) {
       event.preventDefault();
       if($(this).find('i').hasClass('fa-lock')) {
        $(this).parent().parent().removeClass('lock').addClass('unlock');
          $(this).parent().parent().find("input").removeAttr('disabled');
          $(this).parent().parent().find("input").removeAttr('disabled');
          $(this).find('i').removeClass('fa-lock').addClass('fa-unlock');

       } else {
          $(this).parent().parent().removeClass('unlock').addClass('lock');
          $(this).parent().parent().find("input").attr('disabled', 'disabled');
          $(this).find('i').removeClass('fa-unlock').addClass('fa-lock');
        
       }
     });

     $(document).on('click', '.switch-input', function(event) {
      if($(this).is(':checked')) {
       $(this).closest('tr').find(".plage-horaires").hide();
       $(this).closest('tr').find(".no-diffusion").show();
      } else {
       $(this).closest('tr').find(".plage-horaires").show();
       $(this).closest('tr').find(".no-diffusion").hide();
      }

     });

});



/*eslint-enable */

